<template>
  <div v-if="!showBannedAlert && !showUnderMaintenanceMode" id="login-page">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <!-- Icon -->
        <div class="fadeIn first circular--portrait">
          <img :src="logo" id="icon" alt="School Logo" />
        </div>
        <!-- Login Form -->
        <form
          @submit.prevent="login"
          @keydown="form.errors.clear($event.target.name)"
          v-if="!passwordResetStatus"
        >
          <span class="error-message"
            >{{ form.errors.message }} <br />
            <strong v-if="loginAttempts <= 3 && loginAttempts"
              >You Have: {{ 3 - loginAttempts }} More Attempts Left</strong
            >
          </span>
          <div class="login-input">
            <input
              type="text"
              id="login"
              class="fadeIn second"
              :class="form.errors.has('username') ? 'red' : ''"
              autocomplete="off"
              name="username"
              v-model="form.username"
              placeholder="Username"
            />
          </div>
          <div class="login-input">
            <input
              :type="unlock === true ? 'text' : 'password'"
              id="password"
              class="fadeIn third"
              :class="form.errors.has('password') ? 'red' : ''"
              autocomplete="off"
              name="password"
              v-model="form.password"
              placeholder="Password"
            />
            <v-icon v-if="unlock === false" @click="unlock = true">lock</v-icon>
            <v-icon v-else @click="unlock = false">lock_open</v-icon>
          </div>
          <input
            :disabled="
              form.password.length < 8 ||
                form.username.length < 3 ||
                loginAttempts >= 3
            "
            id="login_submit_button"
            type="submit"
            class="fadeIn fourth"
            value="Log In"
          />
        </form>
        <form
          v-if="passwordResetStatus"
          @submit.prevent="forgetPassword"
          @keydown="passwordForgetForm.errors.clear($event.target.name)"
        >
          <span class="error-message"
            >{{ passwordForgetForm.errors.message }} <br />
            <strong v-if="loginAttempts <= 3 && loginAttempts"
              >You Have: {{ 3 - loginAttempts }} More Attempts Left</strong
            >
          </span>
          <div class="login-input">
            <input
              type="text"
              id="username"
              class="fadeIn second"
              :class="passwordForgetForm.errors.has('username') ? 'red' : ''"
              autocomplete="off"
              name="username"
              v-model="passwordForgetForm.username"
              placeholder="Username"
            />
          </div>
          <div class="login-input">
            <input
              type="text"
              id="email"
              class="fadeIn second"
              :class="passwordForgetForm.errors.has('email') ? 'red' : ''"
              autocomplete="off"
              name="email"
              v-model="passwordForgetForm.email"
              placeholder="Email"
            />
          </div>
          <input
            :disabled="
              !passwordForgetForm.email && !passwordForgetForm.username
            "
            type="submit"
            class="fadeIn fourth"
            value="Send Mail"
          />
        </form>
        <!-- Remind Passowrd -->
        <div id="formFooter">
          <a
            class="underlineHover"
            v-if="!passwordResetStatus"
            @click="
              (passwordResetStatus = true),
                (passwordForgetForm.email = ''),
                (passwordForgetForm.username = '')
            "
            >Forgot Password?</a
          >
          <a
            class="underlineHover"
            v-if="passwordResetStatus"
            @click="
              (passwordResetStatus = false),
                (form.passsword = ''),
                (form.username = '')
            "
            >Login</a
          >
        </div>
      </div>
    </div>
    <v-dialog
      @keydown.esc="roleSelectDialog = false"
      v-model="roleSelectDialog"
      persistent
      max-width="400"
      class="role-selecter"
    >
      <!--<v-card>-->

      <v-list two-line>
        <template v-for="(role, index) in roles">
          <v-subheader v-if="index < 1" :key="index">Sign in as</v-subheader>
          <v-divider v-if="index > 0" :key="index" :inset="true"></v-divider>

          <v-list-item
            :key="role.slug"
            avatar
            @click="requestPermissions(role)"
            v-if="role.slug !== 'sms-user' && role.slug !== 'sms-reseller'"
          >
            <v-list-item-avatar>
              <img :src="getAvatar(role.name)" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <strong style="color:#666">{{ role.name }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span> <span class="text--primary">Last Logged In</span></span>
                &mdash;
                <span>{{ getLastLoggedIn(role.slug) }} </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-dialog>

    <v-snackbar
      v-model="snackbarG"
      color="primary"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      Login is currently disabled. Please contact system administration.
      <v-btn color="white" text @click="snackbarG = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
  <h1 v-else-if="showBannedAlert">
    <span style="padding: 50px;"
      >ALAS!!! Our Server is currently down. Please contact system administrator
      for more info.</span
    >
  </h1>
  <under-maintenance v-else-if="showUnderMaintenanceMode"></under-maintenance>
</template>
<script>
import Form from "@/library/Form";
import cookie from "js-cookie";
import { mapActions } from "vuex";
import {
  getPropertyFromArrayObject,
  showBannedAlert,
  showUnderMaintenanceMode,
} from "../../../library/helpers";
import ls from "./../../../library/Storage";
import UnderMaintenance from "../../../components/UnderMaintenance";
import encryption from "@/library/Encryption";
// const NepaliDate = require('nepali-date');
// const nd = new NepaliDate();
import { format, formatDistance, formatRelative, subDays } from "date-fns";

export default {
  components: {
    UnderMaintenance,
  },
  data: () => ({
    requestingPermission: false,
    roleSelectDialog: false,
    unlock: false,
    roleAvatar: [
      {
        name: "administrator",
        avatar: "https://image.flaticon.com/icons/svg/265/265675.svg",
      },

      {
        name: "teacher",
        avatar: "https://image.flaticon.com/icons/svg/1089/1089129.svg",
      },
      {
        name: "accountant",
        avatar: "https://image.flaticon.com/icons/svg/1728/1728858.svg",
      },
      {
        name: "guardian",
        avatar: "https://image.flaticon.com/icons/svg/374/374971.svg",
      },
    ],
    roles: [],
    items: [
      { header: "Sign in as" },
      {
        avatar: "https://image.flaticon.com/icons/svg/265/265675.svg",
        title: "Administrator",
        subtitle:
          "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
      },
      { divider: true, inset: true },
      {
        avatar: "https://image.flaticon.com/icons/svg/1089/1089129.svg",
        title:
          'Teacher <small class="grey--text text--lighten-1">12 Permissions</small>',
        subtitle:
          "<span class='text--primary'>Last Logged In</span> &mdash; 12 Hours Ago",
      },
      { divider: true, inset: true },
      {
        avatar: "https://image.flaticon.com/icons/svg/1728/1728858.svg",
        title: "Accountant",
        subtitle:
          "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
      },
    ],
    form: new Form(
      {
        username: "",
        password: "",
      },
      "/auth/login"
    ),
    passwordResetStatus: false,
    passwordForgetForm: new Form(
      {
        email: "",
        username: "",
      },
      "/password-reset"
    ),
    loginAttempts: null,
    logoFirst: "",

    snackbarG: false,
    y: "top",
    x: null,
    mode: "",
    timeout: 6000,
  }),
  mounted() {
    if ([null, undefined, ""].includes(cookie.get("_cl"))) {
      //API call
      this.fetchSchoolLogo();
    }
  },
  computed: {
    logo() {
      return "https://lms.vidhyalaya.org/img/logo-vidhyalaya.768a1b06.png";
      return cookie.get("_cl")
        ? cookie.get("_cl")
        : this.logoFirst.length > 0
        ? this.logoFirst
        : "https://image.ibb.co/cVTEsG/eAcademy.png";
    },
    showBannedAlert,
    showUnderMaintenanceMode,
  },
  methods: {
    ...mapActions(["setUser", "setBatch"]),

    getProperty: getPropertyFromArrayObject,
    getLastLoggedIn(role) {
      if (role) {
        let lastLogged = ls.get(`_lg_in_${role.toString()}`);
        if (lastLogged) {
          lastLogged = formatRelative(new Date(lastLogged), new Date());
          return lastLogged[0].toUpperCase() + lastLogged.slice(1);
        } else {
          return "Not logged yet";
        }
      }
    },
    async fetchSchoolLogo() {
      await this.$rest.get("api/company-profile").then((data) => {
        cookie.set("_cl", data.data.logo);
        this.logoFirst = data.data.logo;
      });
    },
    login() {
      this.$rest
        .post(this.form.endpoint, this.form.data())
        .then(({ data }) => {
          let currentBatch = null;
          let b = this.$storage.get("batch");
          if (b) {
            currentBatch = b;
          } else {
            currentBatch = encryption.decrypt(data.batch);
          }
          if (currentBatch) {
            currentBatch = JSON.parse(currentBatch);
            this.setBatch({
              id: currentBatch.id,
              start: currentBatch.batch_start,
              end: currentBatch.batch_end,
              name: currentBatch.name,
              is_hs: currentBatch.is_hs,
              is_current_batch: currentBatch.is_current_batch,
            });
          }
          this.$auth.set(data);
          this.setUser(this.$auth.user());
          this.roles = this.$auth.user().roles;
          if (this.roles.length === 1) {
            let role = this.roles[0];
            this.requestPermissions(role);
          } else if (this.roles.length > 1) {
            this.roleSelectDialog = true;
          }
        })
        .catch(({ response }) => {
          if (response) {
            if (response.data.total_attempts !== 0) {
              this.loginAttempts = response.data.total_attempts;
            }
            this.form.errors.record(
              response.data.message,
              response.data.errors
            );
          }
        });
    },
    forgetPassword() {
      console.log("password Reset");
    },
    getAvatar(roleName) {
      roleName = roleName.toLowerCase();
      let avatar = "https://image.flaticon.com/icons/svg/3135/3135715.svg";
      this.roleAvatar.map(function(item) {
        if (item.name === roleName) {
          avatar = item.avatar;
        }
      });
      return avatar;
    },
    requestPermissions(role) {
      if (!role) return;
      if (role.slug === "guardian") {
        this.snackbarG = true;
      } else {
        this.$auth.setRole(role.slug);
        this.requestingPermission = true;
        this.$rest
          .get("/api/me/permissions?for=" + role.slug.toString())
          .then(({ data }) => {
            this.$auth.setPermission(data.permissions);
            this.fetchCommunicatorToken();
            const date = new Date();
            ls.set(`_lg_in_${role.slug.toString()}`, date);
            this.$router.replace({ name: "home" });
          })
          .finally(() => {
            this.requestingPermission = false;
          });
      }
    },

    fetchCommunicatorToken() {
      this.$rest.get("/auth/communicator/token").then((res) => {
        if (res.data.communicate_token) {
          ls.set("_comm_tk", res.data.communicate_token);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#login-page {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"],
input[type="password"] {
  background-color: #f6f6f6;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 95%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input.red {
  color: red;

  &:focus {
    border-bottom: 2px solid red;
  }
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 200px;
  margin-top: 25px;
}

* {
  box-sizing: border-box;
}

input[type="submit"]:disabled {
  cursor: not-allowed;
  background-color: #ccc;
  box-shadow: none;
}

.error-message {
  color: #ef4545;
  padding: 0 15px;
}

.login-input {
  width: 85%;
  margin: auto;
  position: relative;

  .material-icons {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
}
</style>
